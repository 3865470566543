import React, { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../supabaseClient';
import { Navigate } from 'react-router-dom';
import Papa from 'papaparse';

const databaseConfigs = {
  apparel: {
    tableName: 'apparel',
    idField: 'a_id',
    displayName: 'Apparel',
    fieldsToDisplay: ['a_id', 'outfit_name', 'craftable', 'plan_name', 'rarity', 'type'],
  },
  plan: {
    tableName: 'plans',
    idField: 'plan_id',
    displayName: 'Plan',
    fieldsToDisplay: ['plan_id', 'item_type', 'item_name', 'section', 'name', 'location', 'obtainable'],
  },
};

const useAuthorization = (session) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);

  useEffect(() => {
    const checkAuthorization = async () => {
      if (!session) {
        setIsAuthorized(false);
        setIsLoadingAuth(false);
        return;
      }

      const { data: { user } } = await supabase.auth.getUser();
      const currentUserUUID = user?.id;

      const adminUUID = process.env.REACT_APP_ADMIN_UUID;
      const additionalAdminUUIDs = process.env.REACT_APP_ADDITIONAL_ADMIN_UUIDS?.split(',') || [];

      setIsAuthorized(currentUserUUID === adminUUID || additionalAdminUUIDs.includes(currentUserUUID));
      setIsLoadingAuth(false);
    };

    checkAuthorization();
  }, [session]);

  return { isAuthorized, isLoadingAuth };
};

const useDatabaseConfig = (databaseType) => {
  return useCallback(() => databaseConfigs[databaseType], [databaseType]);
};

const useFetchData = (isAuthorized, getConfig) => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    if (!isAuthorized) return;

    const config = getConfig();
    setIsLoadingData(true);
    setLoadingMessage(`Loading ${config.displayName} Data`);
    setLoadingProgress(0);
    setError(null);

    try {
      let allData = [];
      let page = 0;
      const pageSize = 1000;
      let hasMore = true;

      while (hasMore) {
        const { data, error, count } = await supabase
          .from(config.tableName)
          .select('*', { count: 'exact' })
          .range(page * pageSize, (page + 1) * pageSize - 1)
          .order(config.idField, { ascending: true });

        if (error) {
          setError(`Error fetching ${config.displayName} data: ${error.message}. This might be a permissions issue.`);
          setIsLoadingData(false);
          return;
        }

        if (!data) {
          setError(`No ${config.displayName.toLowerCase()} data found. This might be due to an empty table or insufficient permissions.`);
          setIsLoadingData(false);
          return;
        }

        allData = [...allData, ...data];
        hasMore = data.length === pageSize;
        page++;
        setLoadingProgress(Math.round((allData.length / (count || allData.length)) * 100));
      }

      setData(allData);
      setLoadingProgress(100);
    } catch (error) {
      setError(`An unexpected error occurred while fetching ${config.displayName} data. Please check the console for more details.`);
    } finally {
      setIsLoadingData(false);
    }
  }, [isAuthorized, getConfig]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoadingData, loadingMessage, loadingProgress, error, fetchData };
};

const useCsvProcessing = (data, getConfig, fetchData) => {
  const [csvFile, setCsvFile] = useState(null);
  const [csvChanges, setCsvChanges] = useState([]);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const handleCsvButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleCsvUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setCsvFile(file);
    setError(null);
    setCsvChanges([]);

    const config = getConfig();
    const chunkSize = 1024 * 1024; // 1MB chunk size (adjust as needed)
    let allChanges = [];

    Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      chunkSize: chunkSize,
      quoteChar: '"',     // Specify the quote character (default is double quote)
      escapeChar: '"',    // Specify the escape character (default is double quote)
      skipEmptyLines: true, // Skip empty lines
      chunk: (results) => {
        const chunkChanges = results.data.map(row => {
          const change = {};
          Object.keys(row).forEach(key => {
            const value = row[key];
            change[key] = value === '' ? null : value;
          });
          return change;
        });

        allChanges = [...allChanges, ...chunkChanges];
      },
      complete: () => {
        // Process all changes after all chunks are parsed
        const itemsToBeDeleted = data.filter(dbItem =>
          !allChanges.some(csvItem => String(csvItem[config.idField]) === String(dbItem[config.idField]))
        ).map(dbItem => ({
          new: null,
          old: dbItem,
          changeType: 'delete'
        }));
    
        const newAndModifiedItems = allChanges.filter(change => {
          const existingItem = data.find(item => String(item[config.idField]) === String(change[config.idField]));
          if (!existingItem) {
            return true;
          }
          return Object.keys(change).some(key => String(change[key]) !== String(existingItem[key]));
        }).map(change => {
          const existingItem = data.find(item => String(item[config.idField]) === String(change[config.idField]));
          return {
            new: change,
            old: existingItem || null,
            changeType: existingItem ? 'update' : 'insert'
          };
        });
    
        const combinedChanges = [...newAndModifiedItems, ...itemsToBeDeleted];
    
        if (combinedChanges.length > 0) {
          setCsvChanges(combinedChanges);
          setLoadingProgress(10);
        } else {
          setError("No new, modified, or deleted items found in the CSV file.");
        }
      },
      error: (error) => {
        setError(`Error processing CSV file: ${error.message}`);
      }
    });
  };

  const applyCsvChanges = async () => {
    const config = getConfig();
    setIsLoadingCsv(true);
    setLoadingMessage(`Updating ${config.displayName} Database`);
    setLoadingProgress(10);
    setError(null);

    const totalChanges = csvChanges.length;
    let errorOccurred = false;

    const progressIncrement = 90 / totalChanges;

    for (const item of csvChanges) {
      let result;

      if (item.old && !item.new) {
        // Deletion
        result = await supabase
          .from(config.tableName)
          .delete()
          .eq(config.idField, item.old[config.idField]);
      } else {
        Object.keys(item.new).forEach(key => {
          if (item.new[key] === '') {
            item.new[key] = null;
          }
        });
  
        if (item.old) {
          result = await supabase
            .from(config.tableName)
            .update(item.new)
            .eq(config.idField, item.new[config.idField]);
        } else {
          result = await supabase
            .from(config.tableName)
            .insert([item.new]);
        }
      }

      if (result.error) {
        setError(`Error updating/adding ${config.displayName}: ${result.error.message}. This might be a permissions issue.`);
        errorOccurred = true;
        break;
      } else {
        setLoadingProgress(prevProgress => prevProgress + progressIncrement);
      }
    }

    if (!errorOccurred) {
      setCsvChanges([]);
      setCsvFile(null);
      await fetchData();
      setLoadingProgress(100);
    }

    setIsLoadingCsv(false);
  };

  return { csvFile, csvChanges, isLoadingCsv, loadingMessage, loadingProgress, error, fileInputRef, handleCsvButtonClick, handleCsvUpload, applyCsvChanges };
};

const GenericAdminPanel = ({ session }) => {
  const [databaseType, setDatabaseType] = useState('apparel');
  const { isAuthorized, isLoadingAuth } = useAuthorization(session);
  const getConfig = useDatabaseConfig(databaseType);
  const { data, isLoadingData, loadingMessage: dataLoadingMessage, loadingProgress: dataLoadingProgress, error: dataError, fetchData } = useFetchData(isAuthorized, getConfig);
  const { csvFile, csvChanges, isLoadingCsv, loadingMessage: csvLoadingMessage, loadingProgress: csvLoadingProgress, error: csvError, fileInputRef, handleCsvButtonClick, handleCsvUpload, applyCsvChanges } = useCsvProcessing(data, getConfig, fetchData);

  const isLoading = isLoadingAuth || isLoadingData || isLoadingCsv;
  const loadingMessage = dataLoadingMessage || csvLoadingMessage;
  const loadingProgress = dataLoadingProgress || csvLoadingProgress;
  const error = dataError || csvError;

  const totalChangesCount = csvChanges.length;
  const insertionsCount = csvChanges.filter(item => item.changeType === 'insert').length;
  const updatesCount = csvChanges.filter(item => item.changeType === 'update').length;
  const deletionsCount = csvChanges.filter(item => item.changeType === 'delete').length;

  if (isLoadingAuth) {
    return <div>Loading...</div>;
  }

  if (!isAuthorized) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="admin-panel">
      <style>
        {`
          .new-value { color: green; }
          .old-value { color: red; text-decoration: line-through; }
          .value-cell { display: flex; flex-direction: column; }
        `}
      </style>
      <h2>{getConfig().displayName} Database CSV Update</h2>
      <div className="database-selector">
        <label>
          <input
            type="radio"
            value="apparel"
            checked={databaseType === 'apparel'}
            onChange={() => setDatabaseType('apparel')}
          />
          Apparel Database
        </label>
        <label>
          <input
            type="radio"
            value="plan"
            checked={databaseType === 'plan'}
            onChange={() => setDatabaseType('plan')}
          />
          Plan Database
        </label>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className={`content-wrapper ${isLoading ? "blurred" : ""}`}>
        <div className="csv-upload">
          <input 
            type="file" 
            ref={fileInputRef} 
            style={{ display: 'none' }} 
            accept=".csv" 
            onChange={handleCsvUpload} 
          />
          <button onClick={handleCsvButtonClick}>Upload CSV</button>
          {csvFile && (
            <p>Uploaded file: {csvFile.name}</p>
          )}
        </div>
        {csvChanges.length > 0 && (
          <div className="csv-changes-preview">
            <h3>New and Modified Items Preview</h3>
            <p>
              Total Changes: <strong>{totalChangesCount}</strong> (
              {insertionsCount} insertions, {updatesCount} updates, {deletionsCount} deletions)
            </p>
            <table>
              <thead>
                <tr>
                  {getConfig().fieldsToDisplay.map(field => (
                    <th key={field}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvChanges.map(item => (
                  <tr key={item.old ? item.old[getConfig().idField] : Math.random()}>
                  {getConfig().fieldsToDisplay.map(field => (
                    <td key={field}>
                      <div className="value-cell">
                        {item.new && (
                          <span className="new-value">
                            {item.new[field] !== null && item.new[field] !== undefined
                              ? String(item.new[field])
                              : 'NULL'}
                          </span>
                        )}
                        {item.old && item.old[field] !== (item.new ? item.new[field] : undefined) && (
                          <span className="old-value">
                            {item.old[field] !== null && item.old[field] !== undefined
                              ? String(item.old[field])
                              : 'NULL'}
                          </span>
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
                ))}
              </tbody>
            </table>
            <button onClick={applyCsvChanges}>Apply Changes</button>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="upload-overlay">
          <div className="upload-message">
            <h3>{loadingMessage}</h3>
            <p>Please wait while we process your request. This may take a few moments.</p>
            <progress value={loadingProgress} max="100"></progress>
            <p>{loadingProgress.toFixed(2)}% Complete</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenericAdminPanel;